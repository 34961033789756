import React, { useState, useContext } from 'react';
import {
    Dialog,
    Paper,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    CardHeader,
    Box
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import { ShipmentsContextb2b } from '../../context';

const CancelDialog = ({ open, onConfirm }) => {
    const { canceldialog, cancelRemarks, setCancelRemarks, handleCancelModal, onCancelOrder } = useContext(ShipmentsContextb2b);

    return (
        <Dialog open={canceldialog}>
            <Paper style={{ width: '400px', maxWidth: '100%' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <CardHeader sx={{ padding: '1rem' }} title='Cancel Order[s]' />
                    <IconButton onClick={handleCancelModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <TextField
                        label="Enter Remarks....."
                        value={cancelRemarks}
                        onChange={(e) => setCancelRemarks(e.target.value)}
                        fullWidth
                        multiline
                        rows={2}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onCancelOrder} color="primary" disabled={!cancelRemarks}>
                        Confirm
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    );
};

export default CancelDialog;
