import React, { useState, useContext, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { CommonContext, ShipmentsContextb2b } from '../../context';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
};

const ShipmentsTab = () => {
    const {
        setFirstLoading,
        setOsId,
        setAllOrders,
        setAllData,
        osId,
        setFDateRange,
        setFStartDate,
        setFEndDate,
        setFilterType,
        setSearchValue,
        setSelectedCourierIds,
        setSelectedMerchantIds,
        setSelectedOrderStatusIds,
    } = useContext(ShipmentsContextb2b);
    const [value, setValue] = useState(1);

    const {
        setGlobalSearch,
    } = useContext(CommonContext);

    const handleChange = (event, newValue) => {
        setAllOrders([]);
        setAllData([]);
        setFDateRange(['', '']);
        setFStartDate(null);
        setFEndDate(null);
        setFirstLoading(false);
        setFilterType('LR No.');
        setSearchValue('');
        setSelectedOrderStatusIds([]);
        setSelectedCourierIds([]);
        setSelectedMerchantIds([]);
        setOsId(newValue);
        setValue(newValue);
        setGlobalSearch('')
    };
    useEffect(() => {
        setValue(osId)
    }, [osId])
    return (
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" sx={{ marginBottom: '1rem' }} className='tab-section'>
                <Tab label="Today Uploads" value={1} />
                <Tab label="Manifest" value={2} />
                <Tab label="NDR" value={4} />
                <Tab label="In Transit" value={5} />
                <Tab label="Delivered" value={8} />
                <Tab label="OFD" value={7} />
                <Tab label="Returned to Seller" value={12} />
                <Tab label="Booking Failed" value={0} />
                <Tab label="All" value={99} />
            </Tabs>
            {/* <TabPanel value={value} index={0}>
        Content for Tab 1
      </TabPanel>
      <TabPanel value={value} index={1}>
        Content for Tab 2
      </TabPanel>
      <TabPanel value={value} index={2}>
        Content for Tab 3
      </TabPanel>
      <TabPanel value={value} index={3}>
        Content for Tab 4
      </TabPanel> */}
        </div>
    );
};

export default ShipmentsTab;
