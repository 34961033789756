import React from 'react'
import { ShipmentsContextb2bProvider } from '../../context/ShipmentsContextb2b';
import ShipmentsScreenView from './ShipmentsScreenView';

const ShipmentsViewb2b = () => {
  return (
    <ShipmentsContextb2bProvider>
      <ShipmentsScreenView />
    </ShipmentsContextb2bProvider>
  )
}

export default ShipmentsViewb2b