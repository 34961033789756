import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Logo from "../../logo.svg";
import commonApi from "../../utils/api";
import { CommonContext } from "../../context";
// import Loader from "./Loader";
import AlertMessage from "./AlertMessage";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Loader from "./Loader ";

const useQueryParams = () => {
  const { search } = useLocation();
  return Object.fromEntries(new URLSearchParams(search));
};

const secretKey = "app-secret"; // Replace this with your actual secret key

const encryptData = (data, secretKey) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const defaultTheme = createTheme();

export default function MerchantSignIn() {
  const {
    isLoading,
    setIsLoading,
    openAlert,
    setSeverity,
    setAlertMesaage,
    setOpenAlert,
    setUser,
  } = useContext(CommonContext);
  
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  useEffect(() => {
    const encryptedUser = localStorage.getItem("userApp");
    if (encryptedUser) {
      const user = decryptData(encryptedUser, secretKey);
      setUser(user);
    }
  }, [setUser]);

  useEffect(() => {
    console.log("queryParams", queryParams);
    if (Object.keys(queryParams).length > 0) {
      autoSubmitForm(queryParams);
    }
  }, []);

  const autoSubmitForm = (params) => {
    setIsLoading(true);

    commonApi
      .post("/merchant/login", params)
      .then((response) => {
        setIsLoading(false);
        if (response.data.s === 1) {
          const userData = response?.data?.data;
          const encryptedUserData = encryptData(userData, secretKey);
          localStorage.setItem("userApp", encryptedUserData);
          localStorage.setItem("userToke", response?.data?.token);
          localStorage.setItem("userType", "Merchant");
          localStorage.setItem("ordersEvent", JSON.stringify(response?.data?.events));
          setUser(userData);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setAlertMesaage(response?.data?.msg);
          setSeverity("error");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setAlertMesaage(error?.response?.data?.msg || "Something went wrong");
        setSeverity("error");
          setOpenAlert(true);
          console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    const errors = {};

    if (!password) {
      errors.password = "Password is required";
    }
    if (!email) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email format";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      const data = {
        email,
        password,
      };
      commonApi
        .post("/merchant/login", data)
        .then((response) => {
          setIsLoading(false);
          if (response.data.s === 1) {
            const userData = response?.data?.data;
            const encryptedUserData = encryptData(userData, secretKey);
            localStorage.setItem("userApp", encryptedUserData);
            localStorage.setItem("userToke", response?.data?.token);
            localStorage.setItem("userType", "Merchant");
            localStorage.setItem("ordersEvent", JSON.stringify(response?.data?.events));
            setUser(userData);
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            setAlertMesaage(response?.data?.msg);
            setSeverity("error");
            setOpenAlert(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {isLoading && <Loader />}
      {openAlert && <AlertMessage />}
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(./img/bg1.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ my: 2 }}>
              <img src={Logo} alt="Logo" loading="lazy" />
            </Box>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={!!formErrors.email}
                helperText={formErrors.email}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!formErrors.password}
                helperText={formErrors.password}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
