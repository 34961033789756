import React, { useContext, useEffect, useState } from "react";
import { Modal, Box, Button, TextField, Typography, IconButton, Container, CardHeader, Alert, AlertTitle, CircularProgress, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ShippingRechargeModal.scss";
import commonApi from "../../utils/api";
import { CommonContext } from "../../context";
import { ReceiptSharp } from "@mui/icons-material";

const ShippingRechargeModal = () => {
    const { user } = useContext(CommonContext);
    const [payamentData, setPayamentData] = useState("");
    const [formTemp, setFormTemp] = useState({
        merchant_amt: 500,
        utr_number: "",
        isRemittance: false,
        isNeft: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Validate that merchant_amt is a number and not a decimal
        if (name === 'merchant_amt' && (isNaN(value) || value.includes('.'))) {
            return; // Ignore non-numeric or decimal input
        }
        setFormTemp(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        const errors = {};
        // Convert formTemp.merchant_amt to string for validation
        const amountStr = String(formTemp.merchant_amt);
        if (!amountStr || isNaN(amountStr) || amountStr.includes('.')) {
            errors.merchant_amt = 'Amount is required and must be a whole number';
        }
        if (!formTemp.utr_number && formTemp.isNeft) {
            errors.utr_number = 'UTR Number is required for NEFT';
        }
        // Add any additional validation as needed
        if (user) {
            formTemp.merchant_id = user.id;
            formTemp.merchant_email = user.email;
            formTemp.merchant_mobile = user?.mobile;
            formTemp.merchant_name = user?.company_name;
        }
        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            // Handle errors (e.g., show error messages)
            return;
        }

        try {
            setIsLoading(true); // Set loading state
            const response = await commonApi.post('merchant-recharge/payu-request', formTemp); // Call the common API with formTemp
            setIsLoading(false); // Reset loading state
            setOpenAlert(true); // Show alert

            if (response.data.s === 1) {
                if (formTemp?.isOnline) {
                    setPayamentData(response.data.msg);
                } else {
                    setAlertMesaage(response.data.msg);
                    setSeverity('success');
                    setFormTemp(prev => ({ ...prev, merchant_amt: 500, utr_number: "", isNeft: false, isRemittance: false }));
                    setTimeout(() => {
                        setOpenAlert(false);
                        setAlertMesaage('');
                        setSeverity(''); // Reset alert state
                        handleClose();
                    }, 5000);
                }
            } else {
                // Handle error response
                setAlertMesaage(response?.data?.msg || 'An error occurred while processing your request');
                setSeverity('error');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setOpenAlert(true);
            setAlertMesaage(error?.response?.data?.msg || 'An error occurred while processing your request');
            setSeverity('error');
            setIsLoading(false); // Reset loading state
        }
    };

    const handleNeftOpen = () => {
        setFormTemp(prev => ({ ...prev, isNeft: true, isRemittance: false, isOnline: false, merchant_amt: '' }));
    };
    const handleNeftClose = () => {
        setFormTemp(prev => ({ ...prev, isNeft: false, isOnline: true, merchant_amt: 500 }));
    };

    const handleOpen = () => setFormTemp(prev => ({ ...prev, isOnline: true }));
    const handleClose = () => {
        setFormTemp(prev => ({ ...prev, isOnline: false, merchant_amt: 500, utr_number: "", isNeft: false, isRemittance: false }));
    };

    const handleRemittanceOpen = () => {
        setFormTemp(prev => ({ ...prev, isRemittance: true, isNeft: false, isOnline: false, merchant_amt: '' }));
    };
    const handleRemittanceClose = () => {
        setFormTemp(prev => ({ ...prev, isRemittance: false, isOnline: true, merchant_amt: 500 }));
    }
    useEffect(() => {
        if (payamentData) {
            const div = document.createElement("div");
            div.innerHTML = payamentData;

            // Extract the form and script
            const form = div.querySelector("form");
            const script = div.querySelector("script");

            if (form) {
                document.body.appendChild(form);
                form.submit();
            }

            if (script) {
                eval(script.innerText); // Execute the auto-submit script
            }

            return () => {
                // Clean up: Remove form after submission
                if (form) {
                    document.body.removeChild(form);
                }
            };
        }
    }, [payamentData]);
    return (
        <div>
            <Button variant="contained" className="recharge-button" onClick={handleOpen} startIcon={<ReceiptSharp />}>
                Recharge
            </Button>

            <Modal open={formTemp.isOnline || formTemp.isRemittance || formTemp.isNeft} onClose={handleClose} size="small" className="recharge-modal">
                <Container className={'formContainer'} maxWidth="sm">
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <CardHeader sx={{ padding: '0' }} title={formTemp.isRemittance ? `${'Recharge your Wallet'} From COD Remittance` : formTemp.isNeft ? `${'Make'} NEFT` : `${'Upgrade your Shopping Limit'}`} />
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: 0, left: 0, width: '100%', background: '#3333331f', zIndex: 9999 }}>
                        <CircularProgress />
                    </div>}
                    {openAlert && (
                        <Alert
                            severity={severity}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => setOpenAlert(false)}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            <AlertTitle>{severity.charAt(0).toUpperCase() + severity.slice(1)}</AlertTitle>
                            {alertMesaage}
                        </Alert>
                    )}
                    {formTemp.isRemittance ? (
                        <>
                            <div className="input-container">
                                <label>Amount</label>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter Amount"
                                    name="merchant_amt"
                                    value={formTemp.merchant_amt}
                                    onChange={handleChange}
                                    className="merchant_amt-input"
                                    error={!!errors.merchant_amt}
                                    helperText={errors.merchant_amt}
                                />
                            </div>
                            <Typography variant="body2" className="note-text">
                                <strong>Note:</strong> Nearest possible merchant_amt will be used for wallet recharge.
                            </Typography>
                            <Box className="action-buttons">
                                <Button variant="contained" className="primary-btn" fullWidth onClick={handleSubmit}>
                                    Submit
                                </Button>
                                <div className="bottom-buttons">
                                    <Button variant="outlined" className="secondary-btn" onClick={handleRemittanceClose}>Recharge Online</Button>
                                    <Button variant="outlined" className="secondary-btn" onClick={handleNeftOpen}>Pay NEFT</Button>
                                </div>
                            </Box>
                        </>
                    ) : formTemp.isNeft ? (
                        <>
                            <Grid container className="neft-container">
                                <Grid item xs={6} className="bank-details">
                                    <Typography variant="body1"><strong>NEFT Bank Details</strong></Typography>
                                    <Typography variant="body2">XXX XXX XXX</Typography>
                                    <Typography variant="body2">XXX XXX XXX</Typography>
                                    <Typography variant="body2"><strong>A/c XXX XXX XXX</strong></Typography>
                                    <Typography variant="body2"><strong>XXX XXX XXX</strong></Typography>
                                    <Typography variant="body2">XXX XXX XXX</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box className="input-container">
                                        <Typography variant="body1">Amount</Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Enter Amount"
                                            name="merchant_amt"
                                            value={formTemp.merchant_amt}
                                            onChange={handleChange}
                                            error={!!errors.merchant_amt}
                                            helperText={errors.merchant_amt}
                                        />
                                    </Box>

                                    <Box className="input-container">
                                        <Typography variant="body1">UTR Number</Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Bank txn ref#"
                                            name="utr_number"
                                            value={formTemp.utr_number}
                                            onChange={handleChange}
                                            error={!!errors.utr_number}
                                            helperText={errors.utr_number}
                                        />
                                    </Box>

                                    {/* Submit Button */}
                                    <Box className="action-buttons">
                                        <Button variant="contained" className="primary-btn" fullWidth onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <div className="bottom-buttons">
                                <Button variant="outlined" className="secondary-btn" onClick={handleNeftClose}>Recharge Online</Button>
                                <Button variant="outlined" className="secondary-btn" onClick={handleRemittanceOpen}>
                                    Recharge from Remittance
                                </Button>
                            </div>
                        </>
                    )
                        : (
                            <>
                                <Typography variant="body1" className="modal-subtitle">
                                    Enter the amount for your recharge
                                </Typography>

                                <div className="input-container">
                                    <label>Amount</label>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="merchant_amt"
                                        value={formTemp.merchant_amt}
                                        onChange={handleChange}
                                        className="merchant_amt-input"
                                        error={!!errors.merchant_amt}
                                        helperText={errors.merchant_amt}
                                    />
                                </div>
                                <Box className="merchant_amt-buttons">
                                    {[500, 1000, 2000, 5000, 10000].map((value) => (
                                        <Button
                                            key={value}
                                            variant={formTemp.merchant_amt === value ? "contained" : "outlined"}
                                            onClick={() => setFormTemp(prev => ({ ...prev, merchant_amt: value }))}
                                            className={`merchant_amt-btn ${formTemp.merchant_amt === value ? "active" : ""}`}
                                        >
                                            {value}
                                        </Button>
                                    ))}
                                </Box>
                                <Box className="action-buttons">
                                    <Button variant="contained" className="primary-btn" fullWidth onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                    <div className="bottom-buttons">
                                        <Button variant="outlined" className="secondary-btn" onClick={handleRemittanceOpen}>
                                            Recharge from Remittance
                                        </Button>
                                        <Button variant="outlined" className="secondary-btn" onClick={handleNeftOpen}>
                                            Pay NEFT
                                        </Button>
                                    </div>
                                </Box>
                            </>
                        )}
                </Container>
            </Modal>
        </div>
    );
};

export default ShippingRechargeModal;
