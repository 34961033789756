import React, { useState, useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
// import InputAdornment  from '@mui/material/InputAdornment ';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Radio from '@mui/material/Radio';
import { CommonContext, ShipmentsContextb2b } from '../../context';
import { CardHeader } from '@mui/material';
import { ChevronRight, Close as CloseIcon, Download } from '@mui/icons-material';
import commonApi from '../../utils/api';
import axios from 'axios';
import { Link } from 'react-router-dom';


const AddShipments = () => {
    const { addModal, handleCloseModal, handleAlertModalModal, setUploadDataRes, onLoadApi, setIsLoading, setOpenAlert, setAlertMesaage, setSeverity } = useContext(ShipmentsContextb2b)
    const { user } = useContext(CommonContext)
    const [formErrors, setFormErrors] = useState({});

    const [formDataTemp, setFormDataTemp] = useState({
        file: null,
    });

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        console.log('type', type, name)
        setFormDataTemp((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? event.target.files[0] : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = {};
        if (formDataTemp.file === null) {
            errors.file = 'File is required';
        }
        setFormErrors(errors);

        // If there are no errors, you can submit the form data
        if (Object.keys(errors).length === 0) {
            setIsLoading(true);
            let formData = new FormData();
            if (user?.user_type === "Merchant") {
                formData.append('merchant_id', user?.id)
            } else {
                formData.append('admin_id', user?.id)
            }
            formData.append('file', formDataTemp.file)
            // axios({
            //     method: "post",
            //     url: 'https://b2bconnect.gaintlogistic.com/v1/orders/upload',
            //     data: formData,
            //     headers: { "token": localStorage.getItem('userToke') }
            // })
            commonApi.post('orders/upload-b2b', formData)
            .then((res) => {
                setIsLoading(false);
                handleCloseModal();
                setFormDataTemp({
                    file: null,
                });
                if (res?.data?.s === 0) {
                    setUploadDataRes(res?.data)
                    handleAlertModalModal();
                } else {
                    setOpenAlert(true);
                    setAlertMesaage(res?.data?.msg);
                    setSeverity('success');
                    setUploadDataRes();
                    onLoadApi();
                }
            }).catch((err) => {
                setFormDataTemp({
                    file: null,
                });
                handleCloseModal();
                setIsLoading(false);
                setOpenAlert(true);
                setAlertMesaage(err?.res?.data?.msg);
                setSeverity('error');
                console.log('err', err)
            });
        }
    };

    return (
        <Modal open={addModal} onClose={handleCloseModal}>
            <Container className={'formContainer'}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <CardHeader sx={{ padding: '0' }} title='Upload Orders' />
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <form onSubmit={handleSubmit}>
                    <p className="mb-2"><span className="mr-2"><ChevronRight /> </span>Upload all Your Orders via XLSX</p>
                    <p className="mb-2"><span className="mr-2"><ChevronRight /> </span>Please download below given template</p>
                    <p className="mb-2"><span className="mr-2"><ChevronRight /> </span>Remove sample data and upload your data</p>
                    <p className="mb-2"><span className="mr-2"><ChevronRight /> </span>Make sure you atleast fill mandatory fields.</p>
                    <p className="mb-2"><span className="mr-2"><ChevronRight /></span>
                        <Link target="_blank" download to="/format/orders_samples_b2b.xlsx" className=""><Download /> Download
                        </Link>
                        <span className="ml-2">Order Format File</span></p>
                    <Grid container spacing={3} sx={{ marginTop: '1rem' }}>
                        <Grid item xs={12} sm={12} sx={{ marginBottom: '1rem' }}>
                            <Paper className={'paper'}>
                                <div className={'column-form'}>
                                    <FormControl component="fieldset" title='Upload Orders' sx={{ width: '100%' }}>
                                        <Input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            name='file'
                                            onChange={handleChange}
                                            inputProps={{ accept: '.xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }}
                                            error={!!formErrors.file}
                                        // helperText={formErrors.file}
                                        />
                                        <label htmlFor="fileInput" style={{ textAlign: 'center' }}>
                                            <Button
                                                variant="outlined"
                                                component="span"
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Upload Orders
                                            </Button>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                disabled={!formDataTemp.file}
                                            >
                                                <CloudUploadIcon />
                                            </IconButton>
                                            {formDataTemp?.file && (
                                                <div>
                                                    Selected File: {formDataTemp.file.name}
                                                </div>
                                            )}
                                        </label>
                                    </FormControl>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', textAlign: 'right' }}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Box>
                </form>
            </Container >
        </Modal>


    );
};

export default AddShipments;
