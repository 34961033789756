import React, { useState, useEffect, useContext } from 'react'
import BranchTable from './ShipmentsTable'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CardHeader } from '@mui/material';
import SearchFilter from './SearchFilter';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { CommonContext, ShipmentsContextb2b } from '../../context';
import commonApi from '../../utils/api';
import AddShipments from './AddShipments';
import { Link } from 'react-router-dom';
import UploadAlert from './UploadAlert';
import Loader from '../common/Loader ';
import { Delete, Download, RemoveRedEye, } from '@mui/icons-material';
import AlertMessage from './AlertMessage';
import CancelDialog from './CancelDialog';
import ShipmentsTab from './ShipmentsTab';
import TrackingPanel from './TrackingPanel';

const ShipmentsScreenView = () => {
  const { isLoading, openAlert, setCancelDialog, selected, onExportOrder, handleAddModal, recheckStatus } = useContext(ShipmentsContextb2b);
  const { user, userPermission } = useContext(CommonContext);


  const [filters, setFilters] = useState({});
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    // Perform actions like sending API requests with the new filters
    // Example: fetchFilteredData(newFilters);
  };
  return (
    <div className='merchant-main'>
      <CancelDialog />
      {openAlert && <AlertMessage />}
      {isLoading && <Loader />}
      <UploadAlert />
      <AddShipments />
      <Box sx={{ width: '100%' }} className='merchant-box'>
        <TrackingPanel />
        <Box sx={{ display: 'flex', padding: '16px', justifyContent: 'space-between', flexWrap: 'wrap' }} className=''>
          <CardHeader title={`All Orders B2B`} className='page-title' />
          <Box sx={{ display: 'flex', padding: '16px', alignItems: 'center', flexWrap: 'wrap' }} className='link-group'>
            {(user) && (user?.id === 1 || userPermission?.Orders?.add || user?.user_type === "Merchant") &&
              <Link to='/create-order-b2b'>Create Order</Link>
            }
            {user && user?.user_type !== "Merchant" &&
              <Button variant="outlined" endIcon={<AddIcon />} onClick={handleAddModal} className='primary-button' sx={{ marginLeft: '1rem' }}>
                Upload Order
              </Button>
            }
            {(user) && (user?.id === 1 || userPermission?.Orders?.export || user?.user_type === "Merchant") &&
              <Button variant="outlined" endIcon={<Download />} onClick={onExportOrder} className='primary-button' sx={{ marginLeft: '1rem' }}>
                Export Order[s]
              </Button>
            }
            {(user) && (user?.id === 1 || userPermission?.Orders?.cancel || user?.user_type === "Merchant") &&
              <Button variant="outlined" disabled={!selected.length} endIcon={<Delete />} onClick={() => setCancelDialog(true)} className='primary-button' sx={{ marginLeft: '1rem' }}>
                Cancel Order[s]
              </Button>
            }
            {user?.user_type !== "Merchant" && (
              <Button variant="outlined" disabled={!selected.length} endIcon={<RemoveRedEye />} onClick={() => recheckStatus()} className='primary-button' sx={{ marginLeft: '1rem' }}>
                Recheck Status
              </Button>
            )}
          </Box>
        </Box>
        <SearchFilter onFilterChange={handleFilterChange} />
        <ShipmentsTab />
        <Paper sx={{ width: '100%', mb: 2 }}>
          {(user) && (user?.id === 1 || userPermission?.Orders?.list || user?.user_type === "Merchant") &&
            <BranchTable />
          }
        </Paper>
      </Box>
    </div>
  )
}

export default ShipmentsScreenView