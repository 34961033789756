import React from 'react';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material';

const TableSkeletonLoader = ({ rowCount, colCount }) => {
    const skeletonRows = Array.from({ length: rowCount }, (_, index) => (
        <TableRow key={index}>
            {Array.from({ length: colCount }, (_, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" width={100} height={10} />
                </TableCell>
            ))}
        </TableRow>
    ));

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
            >
                <TableHead>
                    <TableRow>
                        {Array.from({ length: colCount }, (_, index) => (
                            <TableCell key={index}>
                                <Skeleton animation="wave" width={100} height={10} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{skeletonRows}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableSkeletonLoader;
