import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NoteIcon from '@mui/icons-material/NoteAlt';
import LayersIcon from '@mui/icons-material/Layers';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MuiAppBar from '@mui/material/AppBar';
import Logo from '../../logo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { CommonContext } from '../../context';
import { Business, BusinessCenterTwoTone, CloudCircle, ConnectedTv, CreditCard, CurrencyRupee, MapOutlined, MultilineChart, ReceiptSharp, ScannerOutlined, WarehouseOutlined } from '@mui/icons-material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import PagesIcon from '@mui/icons-material/Pages';
import { CarCrash, CurrencyExchange, NoteAdd, Scale, Warning } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from '@mui/material/Select';
import ShippingRechargeModal from './ShippingRechargeModal';

const drawerWidth = 260;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: 'transparent',
  padding: '1rem 2rem 0 0rem',
  marginTop: '0rem',
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidebar() {
  const { user, setGlobalSearch, globalSearch, handleSearchSubmit, setIsSearch, selectedOption, setSelectedOption } = useContext(CommonContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSubItems, setOpenSubItems] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleInputChange = (e) => {
    setIsSearch(false);
    const query = e.target.value;
    setGlobalSearch(query);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    // Clear user information from localStorage
    localStorage.removeItem('userApp');
    localStorage.removeItem('userToke');
    localStorage.removeItem('userType');
    localStorage.removeItem('ordersEvent');
    // navigate('/admin', { state: globalSearch });
    if (user?.user_type === 'Merchant') {
      navigate('/login');
    } else {
      navigate('/admin');
    }

    // Redirect the user to the login page or perform other logout actions
    // (e.g., clearing session data, redirecting to a different page)
  };

  const menuItems = [
    { name: 'Dashboard', link: '/', icon: <DashboardIcon /> },
    { name: 'Merchant', link: '/merchant', icon: <LayersIcon /> },
    { name: 'Couriers', link: '/couriers', icon: <LocalShippingIcon /> },
    { name: 'Branches', link: '/branches', icon: <WarehouseOutlined /> },
    {
      name: 'Orders', icon: <WidgetsIcon />, subItems: [
        { name: 'B2C', link: '/orders', icon: <BusinessCenterTwoTone /> },
        { name: 'B2B', link: '/orders-b2b', icon: <Business /> }
      ]
    },
    { name: 'Channel', link: '/channel', icon: <MultilineChart /> },
    { name: 'NDR', link: '/ndr', icon: <Warning /> },
    { name: 'Merchant Warehouse', link: '/warehouse', icon: <WarehouseIcon /> },
    { name: 'Pickup Request', link: '/pickupRequest', icon: <PagesIcon /> },
    { name: 'RTO Request', link: '/rtoRequest', icon: <NoteIcon /> },
    { name: 'Users', link: '/users', icon: <GroupAddIcon /> },
    { name: 'IVR', link: '/ivr-recording', icon: <CloudCircle /> },
    { name: 'Tracking', link: '/tracking', icon: <MapOutlined /> },
    { name: 'Scan Status', link: '/scanStatus', icon: <NetworkPingIcon /> },
    { name: 'Serviceability', link: '/serviceability', icon: <NetworkPingIcon /> },
    { name: 'Weight', link: '/weight', icon: <Scale /> },
    { name: 'Freight', link: '/freight', icon: <CurrencyExchange /> },
    { name: 'Remittance', link: '/remittance', icon: <CreditCard /> },
    { name: 'Invoice', link: '/invoice', icon: <NoteAdd /> },
  ];

  const filteredMenuItems = user?.user_type === 'Merchant'
    ? menuItems.filter(item => !['Merchant', 'Branches', 'Users', 'Scan Status', 'Tracking', 'Couriers', 'IVR'].includes(item.name))
    : user?.user_type !== 'Merchant' ? menuItems.filter(item => !['Channel'].includes(item.name)) : menuItems;

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSearchSubmit();
  };
  console.log('user', user);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" sx={{ boxShadow: 'none !important', zIndex: '9 !important' }}>
        <Card className='header-card'>
          <Box>
            {/* <img
              src={Logo}
              alt={'Logo'}
              loading="lazy"
              style={{ height: '70px' }}
            /> */}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ '& .MuiInputBase-root': { backgroundColor: '#fff', marginRight: '0.5rem', borderRadius: '1rem', paddingLeft: '0.5rem' } }}>
              <form onSubmit={handleFormSubmit} className='top-search-form'>
                <Link to='/download' style={{ textTransform: 'none', color: 'inherit', marginRight: '1rem' }}><CloudDownloadIcon /></Link>
                {user && user?.user_type === "Merchant" && (
                  <Link to='/price-calulator' style={{ textTransform: 'none', color: 'inherit', marginRight: '0.5rem' }}>Price Calculator</Link>
                )
                }
                <Box sx={{ display: 'flex', alignItems: 'center' }} className='top-search'>
                  <Select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ marginRight: '0.5rem' }}
                    className='select-input'
                  >
                    {(user?.user_type !== "Merchant" || user?.service_type?.split(",").includes('1')) && <MenuItem value="B2C">B2C</MenuItem>}
                    {(user?.user_type !== "Merchant" || user?.service_type?.split(",").includes('2')) && <MenuItem value="B2B">B2B</MenuItem>}
                  </Select>
                  <InputBase
                    placeholder={`Search By ${selectedOption === 'B2C' ? 'AWB' : 'LR'} No.`}
                    value={globalSearch}
                    onChange={handleInputChange}
                    inputProps={{
                      'aria-label': 'search input',
                      onKeyPress: (e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSearchSubmit();
                        }
                      }
                    }}
                    endAdornment={
                      <IconButton onClick={handleSearchSubmit} style={{ cursor: 'pointer' }}>
                        <SearchIcon />
                      </IconButton>
                    }
                    className='search-input'
                  />
                </Box>
              </form>
            </Box>
            {user?.user_type === "Merchant" &&
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ShippingRechargeModal />
                <Button color="inherit" startIcon={<CurrencyRupee />}>{user?.amount || 0}</Button>
              </Box>
            }
            <Box sx={{ position: 'relative' }}>
              <Button
                onClick={handleClick}
                color="inherit"
                startIcon={<Avatar><AccountCircle /></Avatar>}
              >
                {user?.user_type === "Merchant" ? user?.brand_name : user?.name}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {user?.user_type === "Merchant" && <MenuItem> <Link to='/siteSetting' style={{ textTransform: 'none', color: 'inherit' }}>Site Setting</Link></MenuItem>}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Card>
      </AppBar>
      <Box onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
        <Drawer variant="permanent" open={open} sx={{
          "& .MuiPaper-root": {
            backgroundColor: open ? ' #fff' : 'transparent',
            border: 0,
            boxShadow: open ? '0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19)' : 'none',
            zIndex: '9 !important'
          }
        }}>
          <DrawerHeader sx={{ justifyContent: 'start', padding: '8px 0px' }}>
            <img
              src={Logo}
              alt={'Logo'}
              loading="lazy"
              style={{ height: '70px', position: 'fixed', top: '8px' }}
            />
          </DrawerHeader>
          <List sx={{ padding: '0 15px' }} className='main-menu'>
            {filteredMenuItems.map((item, index) => (
              <>
                {item.subItems ? (
                  <ListItem key={index} disablePadding sx={{ display: 'block', color: '#6f6b7d' }} onClick={(e) => { e.stopPropagation(); setOpenSubItems(openSubItems === index ? null : index); }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'space-between',
                        px: 0,
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 'auto',
                            justifyContent: 'center',
                            color: '#6f6b7d',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        {open && <ListItemText primary={item.name} sx={{ opacity: 1 }} />}
                      </Box>
                      <ExpandMoreIcon sx={{ transform: openSubItems === index ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <NavLink to={item.link} style={{ textDecoration: 'none' }}>
                    <ListItem disablePadding sx={{ display: 'block', color: '#6f6b7d' }}>
                      <ListItemButton>
                        <ListItemIcon sx={{ color: '#6f6b7d' }}>
                          {item.icon}
                        </ListItemIcon>
                        {open && <ListItemText primary={item.name} sx={{ opacity: 1 }} />}
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                )}
                {/* Dropdown for Orders */}
                {openSubItems === index && item.subItems && (
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem, subIndex) => (
                      <>
                        {(subItem.name === 'B2C' && (user?.user_type !== "Merchant" || user?.service_type?.split(",").includes('1'))) && (
                          <NavLink key={subIndex} to={subItem.link} style={{ textDecoration: 'none' }}>
                            <ListItem disablePadding sx={{ pl: 4 }}>
                              <ListItemButton>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: 'auto',
                                      justifyContent: 'center',
                                      color: '#6f6b7d',
                                      paddingRight: '15px',
                                    }}
                                  >
                                    {subItem.icon}
                                  </ListItemIcon>
                                  {open && <ListItemText primary={subItem.name} sx={{ opacity: 1 }} />}
                                </Box>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>
                        )}
                        {(subItem.name === 'B2B' && (user?.user_type !== "Merchant" || user?.service_type?.split(",").includes('2'))) && (
                          <NavLink key={subIndex} to={subItem.link} style={{ textDecoration: 'none' }}>
                            <ListItem disablePadding sx={{ pl: 4 }}>
                              <ListItemButton>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: 'auto',
                                      justifyContent: 'center',
                                      color: '#6f6b7d',
                                      paddingRight: '15px',
                                    }}
                                  >
                                    {subItem.icon}
                                  </ListItemIcon>
                                  {open && <ListItemText primary={subItem.name} sx={{ opacity: 1 }} />}
                                </Box>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>
                        )}
                      </>
                    ))}
                  </List>
                )}
              </>
            ))}
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}
