import React, { useContext } from 'react'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { CommonContext, ShipmentsContextb2b } from '../../context';

const ShipmentsTableHead = (props) => {
    const { user } = useContext(CommonContext)
    const { osId } = useContext(ShipmentsContextb2b)
    console.log('user', user)
    const headCells = [
        {
            id: 'company_name',
            numeric: false,
            disablePadding: true,
            label: 'MERCHANT NAME',
        },
        {
            id: 'contact_person',
            numeric: false,
            disablePadding: true,
            label: 'WAREHOUSE NAME',
        },
        {
            id: 'customer_name',
            numeric: false,
            disablePadding: true,
            label: 'CUSTOMER NAME',
        },
        {
            id: 'customer_address',
            numeric: false,
            disablePadding: true,
            label: 'CUSTOMER ADDRESS',
        },
        {
            id: 'invoice_no',
            numeric: false,
            disablePadding: true,
            label: 'INVOICE NO.',
        },
        {
            id: 'order_status_id            ',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
        {
            id: 'lrnum',
            numeric: true,
            disablePadding: false,
            label: 'LR NO./ MASTER AWB',
        },
        {
            id: 'customer_city',
            numeric: true,
            disablePadding: false,
            label: 'CUSTOMER CITY',
        },
        {
            id: 'customer_state',
            numeric: true,
            disablePadding: false,
            label: 'CUSTOMER STATE',
        },
        {
            id: 'customer_pincode',
            numeric: true,
            disablePadding: false,
            label: 'CUSTOMER PINCODEs',
        },
        {
            id: 'created_at',
            numeric: true,
            disablePadding: false,
            label: 'CREATED AT',
        },
        {
            id: 'action',
            numeric: true,
            disablePadding: false,
            label: 'Action',
        },
        {
            id: 'api_msg',
            numeric: true,
            disablePadding: false,
            label: 'Message',
        },
    ];

    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const show = true;

    return (
        <TableHead>
            <TableRow>
                {show &&
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
                            checked={props.rowCount > 0 && props.numSelected === props.rowCount}
                            onChange={props.onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                }
                {headCells
                    .filter((headCell) => {
                        if (user?.user_type === 'Merchant' && headCell.id === 'company_name') return false;
                        if (!(osId === 0) && headCell.id === 'api_msg') return false;

                        return true;
                    }).map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            // padding={'none'}
                            sx={{ fontSize: '0.75rem', padding: '3px 7px' }}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                {/* {osId === 5 && <TableCell
                    align={'left'}
                    // padding={'none'}
                    sx={{ fontSize: '0.75rem', padding: '3px 7px' }}
                >
                    E-POD
                </TableCell>} */}
            </TableRow>
        </TableHead>
    );
}

// ShipmentsTableHead.propTypes = {
//     numSelected: PropTypes.number.isRequired,
//     onRequestSort: PropTypes.func.isRequired,
//     onSelectAllClick: PropTypes.func.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//     orderBy: PropTypes.string.isRequired,
//     rowCount: PropTypes.number.isRequired,
// };
export default ShipmentsTableHead