import React, { useState, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Button, IconButton, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShipmentsTableHead from './ShipmentsTableHead'
import { CommonContext, ShipmentsContextb2b } from '../../context';
import TableSkeletonLoader from '../common/TableSkeletonLoader';
import { useNavigate } from 'react-router-dom';
import { order_status_ids } from '../../utils/configb2b';
import moment from 'moment/moment';
import commonApi from '../../utils/api';
// import { IconButton, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

function createData(id, name, address, contact_person, city, state, pincode) {
  return {
    id,
    name,
    address,
    contact_person,
    city,
    state,
    pincode,
  };
}

const rows = [
  createData(719, '3D Marketing Clutch (OPC) Private Limited', 'Tarun@marketingclutch.com', '15-09-2023', '15-09-2023', 'Prepaid', 0.00,),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ShipmentsTable() {
  const { user } = useContext(CommonContext);
  const { allOrders, firstLoading, setSelected, selected, page, setPage, rowsPerPage, setRowsPerPage, setExpandedTracking, setIsLoading, setTrackingInfo, setAwbLr, setOpenAlert, setAlertMesaage, setSeverity, osId } = useContext(ShipmentsContextb2b);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  // const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  // const [dense, setDense] = useState(false);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate()

  const clickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value); // Copying text to clipboard
    setCopied(true); // Setting copied state to true
    setTimeout(() => setCopied(false), 1000); // Resetting copied state after 1.5 seconds
  };


  const closeMore = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allOrders.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // console.log('allOrders', allOrders)
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allOrders.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(allOrders, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, allOrders],
  );
  const onTracking = (id) => {
    setExpandedTracking(false);
    setIsLoading(true);
    commonApi.post('/orders/tracking-b2b', { order_ids: id })
      .then((response) => {
        setIsLoading(false);
        setExpandedTracking(true);
        if (response?.data?.data) {
          setTrackingInfo(response?.data?.data)
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }
  const onPOD = (lrnum, courier_id) => {
    setIsLoading(true);
    commonApi.post('/orders/download-e-pod-b2b', { "lr_number": lrnum, "courier_id": courier_id })
      .then((response) => {
        setIsLoading(false);
        setOpenAlert(true);
        if (response?.data?.s === 0) {
          setAlertMesaage(response?.data?.msg);
          setSeverity('error')
        } else {
          window.location.href = response?.data?.data
          setAlertMesaage('POD Generated');
          setSeverity('success');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  };
  const show = true;
  return (
    <>
      {firstLoading ?
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
              className='table-main'
            >
              <ShipmentsTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={allOrders.length}
              />
              <TableBody>
                {visibleRows.length > 0 ? visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {show &&
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      }
                      {user?.user_type !== "Merchant" && (
                        <TableCell align="left">{row?.merchant?.company_name}</TableCell>
                      )}
                      <TableCell align="left">{row?.merchant_warehouse?.contact_person}</TableCell>
                      <TableCell align="left">{row.customer_name}</TableCell>
                      <TableCell align="left">{row.customer_address}</TableCell>
                      <TableCell align="left">
                        {row.invoice_no}
                      </TableCell>
                      <TableCell align="left">
                        <Button size="small" component="span" variant="outlined" color="primary">
                          {order_status_ids[row.order_status_id]}
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <div className='lr-section'>
                          <Typography sx={{ color: '#004b8d', cursor: 'pointer' }} onClick={() => navigate('/orders-details-b2b', { state: row })}>{row.lrnum}</Typography>
                          <Typography sx={{ color: '#004b8d', cursor: 'pointer' }} onClick={() => navigate('/orders-details-b2b', { state: row })}>{row.awb_no}</Typography>
                          <Tooltip title={copied ? "Copied" : "Copy"}>
                            <IconButton onClick={() => handleCopy(row.lrnum)}>
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Typography sx={{ fontSize: '0.8rem' }}><strong> {row?.courier?.brand_name} </strong></Typography>
                      </TableCell>
                      <TableCell align="left">{row.customer_city}</TableCell>
                      <TableCell align="left">{row.customer_state}</TableCell>
                      <TableCell align="left">{row.customer_pincode}</TableCell>
                      <TableCell align="left">{row.created_at ? moment(row.created_at).format('DD-MM-YYYY HH:mm:ss') : 'NA'}</TableCell>
                      <TableCell align="left">
                        {osId !== 0 ?
                          <Typography
                            sx={{ color: '#004b8d', cursor: 'pointer' }}
                            onClick={() => { onTracking(row?.id); setAwbLr({ awb_no: row.awb_no, lr_no: row.lrnum }); }}
                          >Track</Typography>
                          :
                          <Typography
                            sx={{ color: '#004b8d', cursor: 'pointer' }}
                            onClick={() => { navigate('/create-order-b2b', { state: { id: row.id, is_reship: 1 } }); }}
                          >Reship</Typography>
                        }
                        {row?.order_status_id === 8 ?
                          <Typography
                            sx={{ color: '#177b1e', cursor: 'pointer', marginTop: '0.5rem' }}
                            onClick={() => { onPOD(row?.lrnum, row?.courier_id); setAwbLr({ awb_no: row.awb_no, lr_no: row.lrnum }); }}
                          >E POD</Typography>
                          : ''
                        }
                      </TableCell>
                      {osId === 0 && (
                        <TableCell align="left">
                          <Tooltip title={row?.api_msg || ''} arrow placement="top" variant="light">
                            <Typography
                              sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxHeight: '3rem',
                                whiteSpace: 'normal',
                                cursor: 'pointer',
                              }}
                            >{row?.api_msg ?? 'NA'}</Typography>
                          </Tooltip>
                        </TableCell>)}
                      {/* {osId === 5 &&
                        <TableCell align="left" onClick={() => { onPOD(row?.lrnum, row?.courier_id); setAwbLr({ awb_no: row.awb_no, lr_no: row.lrnum }); }}> <Typography sx={{ color: '#004b8d', cursor: 'pointer' }}>Download</Typography></TableCell>
                      } */}
                    </TableRow>
                  );
                }) :
                  <TableRow
                    style={{
                      height: '250px'
                    }}
                  >
                    <TableCell colSpan={12}>
                      <Typography sx={{ textAlign: 'center' }}>No Orders Found</Typography>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 250]}
            component="div"
            count={allOrders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
        :
        <TableSkeletonLoader rowCount={7} colCount={6} />
      }
    </>

  );
}