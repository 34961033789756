// TrackingPanel.js
import React, { useContext } from 'react';
import { Drawer, List, ListItem, ListItemText, Divider, IconButton, Box, Paper, Button, Collapse, Card, CardContent, Grid, Typography, } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material'; // Import the CloseIcon
import { ShipmentsContextb2b } from '../../context';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment/moment';
import MapIcon from '@mui/icons-material/Map';
import CommentIcon from '@mui/icons-material/Comment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const TrackingPanel = () => {
    const { expandedTracking, setExpandedTracking, trackingInfo, setTrackingInfo, setAwbLr, awbLr } = useContext(ShipmentsContextb2b);

    return (
        <Drawer anchor="right" open={expandedTracking} onClose={() => { setExpandedTracking(null); setTrackingInfo([]); setAwbLr({}) }}>
            <List>
                {/* Header with close icon */}
                <ListItem>
                    <ListItemText primary="Order Tracking" />
                    <IconButton onClick={() => { setExpandedTracking(null); setTrackingInfo([]); setAwbLr({}) }}>
                        <CloseIcon />
                    </IconButton>
                </ListItem>
                <Divider />
                <ListItem>
                    <Typography sx={{ marginRight: '1rem' }}>AWB Number: <strong>{awbLr?.awb_no ? awbLr?.awb_no : 'NA'}</strong></Typography>
                    <Typography>LR Number:  <strong>{awbLr?.lr_no ? awbLr?.lr_no : 'NA'}</strong></Typography>
                </ListItem>
                <Divider />

                {/* Tracking information */}
                {trackingInfo.length > 0 ? trackingInfo.map((items, index) => (
                    <Timeline sx={{ margin: 0, padding: '0 15px' }}>
                        <TimelineItem sx={{ '::before': { display: 'none' } }}>
                            <TimelineSeparator>
                                <TimelineDot style={{ backgroundColor: '#82ccd5' }} />
                                {/* {index !== trackingInfo.length - 1 && ( */}
                                <TimelineConnector style={{ backgroundColor: '#82ccd5' }} />
                                {/* )} */}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper sx={{ width: '100%', borderRadius: 0, boxShadow: 'none' }} elevation={3}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography><strong>{items.status_msg}</strong></Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '14px' }}><CalendarMonthIcon sx={{ fontSize: '14px', color: '#463f5f', marginRight: '0.2rem' }} /> {items.event_date ? moment(items.event_date).format('DD-MM-YYYY') : 'NA'}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '14px',}}><MapIcon sx={{ fontSize: '14px', color: '#463f5f' }} /> {items.location}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '14px',}}><CommentIcon sx={{ fontSize: '14px', color: '#463f5f' }} /> {items.remarks}</Typography>
                                    </Box>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                ))
                    :
                    <Box sx={{ width: '100%', marginTop: '1rem' }}>
                        <Typography sx={{ textAlign: 'center', }}>Tracking not found</Typography>
                    </Box>
                }
            </List>
        </Drawer>
    );
};

export default TrackingPanel;
