import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import { useNavigate } from 'react-router-dom';

export const IVRContext = createContext();

export function IVRContextProvider({ children }) {
    const { user } = useContext(CommonContext);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allRecordings, setAllRecordings] = useState([]);
    const [viewData, setViewData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [severity, setSeverity] = useState('');
    const [recordingurl, setRecordingurl] = useState(null);
    const [isReset, setIsReset] = useState(0);
    const [fDateRange, setFDateRange] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filterInput, setFilterInput] = useState({
        searchBy: 'awb_no',
        searchValue: '',
        from_mobile: '',
        to_mobile: '',
        start_date: '',
        end_date: '',
        status_id: '',
    });
    const [formTemp, setFormTemp] = useState({
        main_remarks: "",
        sub_remarks: "",
        follow_date: null,
        remarks: "",
        id: "",
    });
    const [allStatusChange, setAllStatusChange] = useState([]);
    const [openSmodal, setOpenSmodal] = useState(false);
    const [allSummary, setAllSummary] = useState([]);
    const [openSummary, setOpenSummary] = useState(false);

    const handleOpenSummary = () => setOpenSummary(true);
    const handleCloseSummary = () => setOpenSummary(false);
    const options = [
        { id: 1, label: "RECEIVED", subOptions: [{ id: 11, label: "ON CALL" }, { id: 12, label: "MAIL" }] },
        { id: 2, label: "NOT RECEIVED", subOptions: [] },
        { id: 3, label: "LANGUAGE ISSUE", subOptions: [] },
        { id: 4, label: "CALL ME LATER", subOptions: [{ id: 41, label: "FOLLOW DATE" }] },
        { id: 5, label: "WRONG NUMBER", subOptions: [] },
        { id: 6, label: "DAMAGE/SHORT RECEIVED", subOptions: [] },
        {
            id: 7,
            label: "NOT CONTACTABLE",
            subOptions: [
                { id: 71, label: "NOT ANSWERING" },
                { id: 72, label: "INVALID NUMBER" },
                { id: 73, label: "CALL DISCONNECT BY CUSTOMER" },
                { id: 74, label: "CALL NOT CONNECT" },
                { id: 75, label: "NUMBER BUSY" },
            ],
        },
    ];
    const getStatusLabel = (status) => {
        const foundOption = options.find((option) => option.id === status);
        return foundOption ? foundOption.label : "Unknown Status";
    };
    const onListStatusApi = (id) => {
        let postReq = { recording_id: id };
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        } else {
            postReq.admin_id = user?.id
        }
        setIsLoading(true);
        commonApi.post('/ivr/list-of-order-ivr', postReq)
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.data) {
                    setAllStatusChange(response?.data?.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };
    const handleOpenModal = (row) => {
        setOpenSmodal(true);
        console.log('row', row)
        setFormTemp({
            ...formTemp,
            id: row?.id
        });
        onListStatusApi(row?.id);
    };

    const handleCloseModal = () => {
        setOpenSmodal(false);
        setAlertMesaage('');
        setSeverity('');
        setOpenAlert(false);
        setFormTemp({
            main_remarks: "",
            sub_remarks: "",
            follow_date: null,
            remarks: "",
            if: "",
        });
    };

    const onLoadApi = (pagePrPage) => {
        console.log('pagePrPage', pagePrPage)
        const url = `/ivr/list?page=${pagePrPage?.page ?? 1}&per_page=${pagePrPage?.rowsPerPage ?? 20}`;
        if (user?.user_type === "Merchant") {
            filterInput.merchant_id = user?.id
        } else {
            filterInput.admin_id = user?.id
        }
        setAllRecordings([]);
        setAllData([]);
        setRecordingurl(null);
        setFirstLoading(false);
        commonApi.post(url, filterInput)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllRecordings(response?.data?.data);
                    setAllData(response?.data);
                    setRecordingurl(response?.data?.recording_base_url);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onExport = (row) => {
        setIsLoading(true);
        commonApi.post('/ivr/export', row?.merchant_id ? row : filterInput)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };
    const onSummaryApi = () => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        } else {
            postReq.admin_id = user?.id
        }
        setIsLoading(true);
        commonApi.post('/ivr/summary', postReq)
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.data) {
                    setAllSummary(response?.data?.data);
                    handleOpenSummary();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        onLoadApi();
    }, []);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        isReset,
        setIsReset,
        getStatusLabel,
        onListStatusApi,
        allStatusChange,
        onSummaryApi,
        allSummary,
        handleOpenSummary,
        openSummary,
        handleCloseSummary,
        options,
        setAlertMesaage,
        setSeverity,
        setOpenAlert,
        openSmodal,
        formTemp,
        setFormTemp,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        fDateRange,
        setFDateRange,
        filterInput,
        setFilterInput,
        recordingurl,
        onExport,
        severity,
        alertMesaage,
        openAlert,
        onLoadApi,
        isLoading,
        firstLoading,
        viewData,
        allRecordings,
        allData,
        handleOpenModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllRecordings,
        setViewData,
        setFirstLoading,
        setIsLoading,
    };

    // Render the provider with children
    return <IVRContext.Provider value={contextValue}>{children}</IVRContext.Provider>;
}
