module.exports = {
    AppUrl: 'https://connect.gaintlogistic.com',
    baseUrl: 'https://connect.gaintlogistic.com/v1',
    states: {
        62: 'Arunachal Pradesh', 63: 'Assam', 64: 'Manipur', 65: 'Meghalaya', 66: 'Sikkim', 68: 'Nagaland', 69: 'Tripura', 70: 'Mizoram',
        72: 'Haryana', 73: 'Punjab', 75: 'Uttarakhand', 76: 'Himachal Pradesh', 77: 'Jammu and Kashmir', 78: 'Chandigarh',
        79: 'Rajasthan', 81: 'Kerala', 82: 'Tamil Nadu', 83: 'Telangana', 84: 'Karnataka', 85: 'Andhra Pradesh', 86: 'Delhi', 87: 'Madhya Pradesh', 88: 'Uttar Pradesh', 89: 'Chhattisgarh', 90: 'Andaman and Nicobar',
        91: 'Lakshadweep', 92: 'Bihar', 93: 'Goa', 94: 'Gujarat', 95: 'Jharkhand', 96: 'Orissa', 97: 'West Bengal', 98: 'Maharashtra',
        111: '', 112: '', 113: '', 114: 'Uttaranchal', 115: 'Pondicherry', 116: 'ODISHA', 117: 'KHARSIA', 118: 'Dadra and Nagar Haveli', 119: 'Daman and Diu'
    },
    invoice_status: {1: 'Created', 2: 'Sent',3: 'Approved', 4: 'Cancelled'},
    service: { 1: 'Air', 2: 'Prime', 3: 'Surface' },
    delivery_type_id: { 1: "COD", 2: "PREPAID" },
    booking_type_id: { 1: "Manual", 2: "Automatic" },
    movement_type: { 1: 'Forward', 2: 'Reverse' },
    // pickupStatus: { 1: 'Pickup Completed', 2: 'Pickup Rejected', 3: 'Paritally pickedup', },
    pickupStatus: { 1: "Not Picked", 2: "Picked", 3: "Cancelled", 4: "Open" },
    order_status_ids: {
        1: 'Order Booked', 2: "Order Picked Up", 3: "Order Intransit", 4: "Order Undelivered", 5: "Order Delivered", 6: "Order OFD", 7: "Order RTO Intransit",
        8: "Order Returned to Seller", 9: "Order Lost", 10: "Order Damaged", 11: "Order Cancelled"
    },
    pickup_request: {
        1: 'Pickup Completed', 2: 'Pickup Rejected', 3: 'Paritally pickedup',
    },
    zones: { 1:'In City', 2:'In State', 3:'In Zone', 4:'In Metro',5:'Rest of India', 6:'North East' },
    serviceType: { 1:'B2C', 2:'B2B'},
    // ndr_codes: {
    //     1: "Consignee Invalid/Incomplete Address",
    //     2: "Consignee Unavailable",
    //     3: "Consignee Refused",
    //     4: "Consignee Wants open Delivery",
    //     5: "Consignee Want to Reschedule Delivery",
    //     6: "Delayed",
    //     7: "Consignee Wants to Change Address",
    //     8: "Consignee Hold",
    //     9: "Shipment Lost/Damanged"
    // },
    ndr_codes: {
        1: "Office/Institute/RESIDENCE Closed",
        2: "Customer NOT AVAILABLE",
        3: "Customer did not receive the OTP Code",
        4: "Customer Refuse to share the OTP Code",
        5: "Customer REFUSED TO ACCEPT Order",
        6: "Customer Cancelled Order",
        7: "Customer OUT OF STATION",
        8: "ADDRESS INCOMPLETE/ INCORRECT",
        9: "ADDRESS UNLOCATABLE; CANNOT DELIVER",
        10: "DELIVERY SCHEDULED FOR NEXT WORKING DAY",
        11: "DELIVERY AREA NOT ACCESSIBLE",
        12: "Appointment awaited from consignee",
        13: "Delayed. No Entry",
        14: "OUT OF DELIVERY AREA",
        15: "COD Amount not ready",
        16: "Customer Want Open Delivery",
        17: "Customer Self Collect",
        18: "Fake/Duplicate Order"
    },
    ndr_merchant_action: { 1: "Reattempt", 2: "Change Address", 3: "Change Phone Number", 4: "RTO" },

}