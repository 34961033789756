import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { CardHeader } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { ChevronRight, Close as CloseIcon, Download } from '@mui/icons-material';
import { ShipmentsContextb2b } from '../../context';
import { Link } from 'react-router-dom';

const UploadAlert = () => {
    const { alertModal, handleCloseAlertModalModal, uploadDataRes } = useContext(ShipmentsContextb2b);
    return (
        <Modal open={alertModal}>
            <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', maxWidth: '100%' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <CardHeader sx={{ padding: '1rem' }} title='Upload Orders' />
                    <IconButton onClick={handleCloseAlertModalModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Total Uploads</TableCell>
                            <TableCell>{uploadDataRes?.torder}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Successful Uploads</TableCell>
                            <TableCell>{uploadDataRes?.sorder}</TableCell>
                        </TableRow>
                        {uploadDataRes?.forder > 0 && (
                            <>
                                <TableRow>
                                    <TableCell>Failed Uploads</TableCell>
                                    <TableCell>{uploadDataRes?.forder}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Failed File</TableCell>
                                    <TableCell>
                                        <Link target="_blank" download to={`https://b2bconnect.gaintlogistic.com${uploadDataRes?.errorfile}`} className=""><Download /> Download Error File
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Modal >
    );
};

export default UploadAlert;
