import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import _debounce from 'lodash/debounce';
import { ShipmentsContextb2b, CommonContext } from '../../context';
import { DateRangePicker } from 'rsuite';
import FormControl from '@mui/material/FormControl';
import moment from 'moment/moment';
import { Autocomplete, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { order_status_ids } from '../../utils/configb2b';

const SearchFilter = () => {
  const {
    setFEndDate,
    setFStartDate,
    setFDateRange,
    fDateRange,
    filterType,
    searchValue,
    selectedCourierIds,
    selectedOrderStatusIds,
    setSelectedOrderStatusIds,
    selectedMerchantIds,
    setSelectedMerchantIds,
    setSelectedCourierIds,
    setSearchValue,
    setFilterType,
    onLoadApi,
    osId
  } = useContext(ShipmentsContextb2b)
  const { setGlobalSearch, merchant, user, courier } = useContext(CommonContext);
  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  const dateRangeChange = (date) => {
    setFDateRange(date);
    let sDate = date && date.length ? moment(date[0]).format('YYYY-MM-DD') : '';
    let eDate = date && date.length ? moment(date[1]).format('YYYY-MM-DD') : '';
    setFStartDate(sDate);
    setFEndDate(eDate);
  }
  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setGlobalSearch('')
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setGlobalSearch('')
  };

  const handleSearch = () => {
    onLoadApi();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  const resetFilter = () => {
    setSelectedCourierIds([]);
    setSelectedMerchantIds([]);
    setSelectedOrderStatusIds([]);
    setFilterType('LR No.');
    setSearchValue('');
    setGlobalSearch('');
    setFDateRange(['', '']);
    setFEndDate(null);
    setFStartDate(null);
    setTimeout(() => {
      if (osId === 99) {
        const defaultStartDate = moment().startOf('month');
        const defaultEndDate = moment().endOf('month');
        const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
        setFEndDate(moment(defaultEndDate.toDate()).format('YYYY-MM-DD'));
        setFStartDate(moment(defaultStartDate.toDate()).format('YYYY-MM-DD'));
        setFDateRange(defaultDateRange);
        onLoadApi(defaultDateRange, 'reset');
      } else if (osId === 1) {
        const defaultStartDate = moment();
        const defaultEndDate = moment();
        const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
        setFEndDate(moment(defaultEndDate.toDate()).format('YYYY-MM-DD'));
        setFStartDate(moment(defaultStartDate.toDate()).format('YYYY-MM-DD'));
        setFDateRange(defaultDateRange);
        onLoadApi(defaultDateRange, 'reset');
      } else {
        onLoadApi('reset');
      }
    }, 0)
  };

  const getSelectedCourier = () => {
    return selectedCourierIds
      .map((id) => courier.find((item) => item.id === id)?.full_name)
      .filter(Boolean)
      .join(', ');
  };

  const getSelectedMerchant = () => {
    return selectedMerchantIds
      .map((id) => merchant.find((item) => item.id === id)?.company_name)
      .filter(Boolean)
      .join(', ');
  };

  const getSelectedIds = (selectedOrderStatusIds, orderStatusIds) => {
    return selectedOrderStatusIds
      .map((id) => orderStatusIds[id])
      .filter(Boolean)
      .join(', ');
  };
  const handleMerchantChange = (event, newValue) => {
    setSelectedMerchantIds(newValue.map(item => item.id));
  };
  return (
    <div className="filter-style">
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={1} sx={{ padding: '0.5rem 1rem' }}>
          <Grid item xs={12} sm={4}>
            <Select
              value={filterType}
              onChange={handleFilterChange}
              className='filter-select'
              sx={{ width: '30%' }}
            >
              <MenuItem value="LR No.">LR No.</MenuItem>
              <MenuItem value="awb no.">AWB No.</MenuItem>
              <MenuItem value="invoice no">Invoice No</MenuItem>
              <MenuItem value="customer mobile">Customer Mobile</MenuItem>
              <MenuItem value="order no.">Order No.</MenuItem>
            </Select>
            <TextField
              label={`Search by ${filterType}`}
              variant="outlined"
              value={searchValue}
              onChange={handleSearchChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              className='filter-field'
              sx={{ width: '70%' }}
            />
          </Grid>
          {osId !== 1 &&
            (
              <Grid item xs={12} sm={2}>
                <DateRangePicker
                  value={fDateRange}
                  onChange={date => { dateRangeChange(date) }}
                  placeholder="Select Dates"
                  format="dd-MM-yyyy"
                  ref={dateRangeRef}
                  style={{ width: '100%' }}
                />
              </Grid>
            )}
          {osId === 99 &&
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth className='select-input' sx={{ width: '100%' }}>
                <Select
                  multiple
                  value={selectedOrderStatusIds}
                  onChange={e => setSelectedOrderStatusIds(e.target.value)}
                  renderValue={(selected) =>
                    selected.length ? getSelectedIds(selected, order_status_ids) : 'Select Order Status'
                  }
                  displayEmpty
                  sx={{ width: '100%', textAlign: 'left' }}
                  inputProps={{ 'aria-label': 'Select Order Status' }}
                  className='select-input'
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Merchant
                  </MenuItem>
                  {Object.entries(order_status_ids).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={selectedOrderStatusIds.includes(key)} sx={{ padding: '0px 5px' }} />
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth className='select-input' sx={{ width: '100%' }}>
              <Select
                multiple
                value={selectedCourierIds}
                name="courier"
                onChange={e => { setSelectedCourierIds(e.target.value); console.log('e', e) }}
                renderValue={() => (selectedCourierIds.length ? getSelectedCourier() : 'Select Courier')}
                displayEmpty
                sx={{ width: '100%', textAlign: 'left' }}
                inputProps={{ 'aria-label': 'Select Courier' }}
                className='select-input'
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Courier
                </MenuItem>
                {courier?.length && courier.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={selectedCourierIds.includes(item.id)} sx={{ padding: '0px 5px' }} />
                    <ListItemText primary={item.full_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {user?.user_type !== "Merchant" &&
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth className='select-input' sx={{ width: '100%' }}>
                <Autocomplete
                  multiple
                  options={merchant || []}
                  getOptionLabel={(option) => option.company_name}
                  value={merchant.filter(item => selectedMerchantIds.includes(item.id))}
                  onChange={handleMerchantChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select Merchant" />
                  )}
                  disableCloseOnSelect
                  sx={{ '& .MuiInputBase-root': { paddingTop: '0', paddingBottom: '0' }, margin: '0.5rem 0' }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option.company_name} />
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
            <Button variant="outlined" onClick={resetFilter} style={{ marginLeft: '0.5rem' }}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SearchFilter;
