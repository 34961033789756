module.exports = {
    states: {
        62: 'Arunachal Pradesh', 63: 'Assam', 64: 'Manipur', 65: 'Meghalaya', 66: 'Sikkim', 68: 'Nagaland', 69: 'Tripura', 70: 'Mizoram',
        72: 'Haryana', 73: 'Punjab', 75: 'Uttarakhand', 76: 'Himachal Pradesh', 77: 'Jammu and Kashmir', 78: 'Chandigarh',
        79: 'Rajasthan', 81: 'Kerala', 82: 'Tamil Nadu', 83: 'Telangana', 84: 'Karnataka', 85: 'Andhra Pradesh', 86: 'Delhi', 87: 'Madhya Pradesh', 88: 'Uttar Pradesh', 89: 'Chhattisgarh', 90: 'Andaman and Nicobar',
        91: 'Lakshadweep', 92: 'Bihar', 93: 'Goa', 94: 'Gujarat', 95: 'Jharkhand', 96: 'Orissa', 97: 'West Bengal', 98: 'Maharashtra',
        111: '', 112: '', 113: '', 114: 'Uttaranchal', 115: 'Pondicherry', 116: 'ODISHA', 117: 'KHARSIA', 118: 'Dadra and Nagar Haveli', 119: 'Daman and Diu'
    },
    service: { 1: 'Express', 2: 'Surface', 3: 'Reverse' },
    delivery_type_id: { 1: "COD", 2: "PREPAID" },
    movement_type: { 1: 'Forward', 2: 'Reverse' },
    pickupStatus: { 1: "Not Picked", 2: "Picked", 3: "Cancelled", 4: "Open" },
    order_status_ids: {
        1: 'Booking received', 2: "Pickup Schedule", 3: "Picked", 4: "At Branch/Warehouse",
        5: "In Transit", 6: "Arrived at Destination", 7: "Out for Delivery", 8: "Delivered", 9: "Delivery Failed", 10: "RTO INTRANSIT", 11: "RTO Delivered to Branch/Warehouse",
        12: "RTO Delivered to Merchant", 13: "Forward LOST", 14: "RTO LOST", 15: "Forward Damage", 16: "RTO Damage", 17: "Held UP",
        18: "RTO Pending", 19: "Partially Delivered", 20: "Booking Pending", 23: "Cancelled by Merchant"
    },
    ndr_status: {
        1: 'NDR Pending', 2: 'NDR Closed',
    },
    ndr_codes: {
        1: "Consignee Invalid/Incomplete Address",
        2: "Consignee Unavailable",
        3: "Consignee Refused",
        4: "Consignee Wants open Delivery",
        5: "Consignee Want to Reschedule Delivery",
        6: "Delayed",
        7: "Consignee Wants to Change Address",
        8: "Consignee Hold",
        9: "Shipment Lost/Damanged"
    },
    pickup_request: {
        1: 'Open', 2: 'Completed', 3: 'Cancelled',
    },
    zones: { 1: 'C', 2: 'C1', 3: "E", 4: 'E1', 5: "N", 6: "N1", 7: "N2", 8: "NE", 9: "NE2", 10: "S", 11: "S1", 12: "S2", 13: "SP1", 14: "SP2" },
    lr_generate_type: { 1: 'Automatic', 2: 'Manual' },
    pickup_type: { '1': 'FM-Pickup', 2: 'Self-Drop' },
    insurance_type: { 1: 'Carrier Risk', 2: 'Owner Risks' },
    freight_collection_type: { 1: 'Bill to Client', 2: 'Collect on Delivery (COD)' },
    is_fragile: { 1: 'Yes', 0: 'No' },

}